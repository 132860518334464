<template>
  <table class="dynamic-input-table w-full">
    <tr class="table-header">
      <th v-for="(label, label_index) in labels" :key="label_index">
        {{ label }}
      </th>
    </tr>

    <dynamic-users-input v-if="type === 'users' && tr && tr.show"
                         v-for="(tr, tr_index) in data"
                         :key="`${tr_index}-${tr.id}`"
                         @item:inserted="insertRow(tr)"
                         @item:removed="removeRow(tr)"
                         :suggests="suggestsList"
                         v-model="data[tr_index]"/>


    <tr class="table-footer" v-if="enableCounter && Object.keys(enableCounter).length > 0">
      <th v-for="(label, label_index) in labels"
          class="relative"
          :key="label">
        <span v-if="counter[label_index]">
          <span class="counter-value w-full">
            {{ counter[label_index] | addComma }}
          </span>
          <span class="counter-label">
            {{ $locale.currency() }}
          </span>
        </span>
      </th>
    </tr>
  </table>
</template>

<script>
import customSelect from '@/components/customSelect/customSelect'
import dynamicUsersInput from '@/components/dynamicInputTable/dynamicUsersInput'
import { addComma } from "@/assets/js/functions";

export default {
  name: 'dynamicInputTable',
  components: {
    customSelect,
    dynamicUsersInput
  },
  model: {
    prop: 'data',
    event: 'input'
  },
  props: {
    data: {},
    type: '',
    labels: {},
    suggests: {},
    enableCounter: {}
  },
  data () {
    return {
      showSuggest: false,
      suggestsList: [],
      dataChecker: [],
      counter: {}
    }
  },
  methods: {
    addComma,
    removeItem (id) {
      for (let i = 0; i < this.suggestsList.length; i++) {
        if (this.suggestsList[i].id === id) {
          this.suggestsList.splice(i, 1)
          break
        }
      }
    },
    insertRow () {
      let data_count = 0

      this.data.forEach((item) => {
        if (item.id || item.code) data_count++
      })

      if (data_count === this.data.length) {
        if (this.type === 'users') {
          this.data.push({
            id: '',
            name: '',
            family: '',
            phone_number: '',
            status: '',
            price: '',
            show: true
          })
        } else if (this.type === 'stores') {
          this.data.push({
            code: '',
            name: '',
            properties: [],
            currentProperty: {
              label: '-',
              value: 0
            },
            number: 0,
            unitAmount: '',
            amount: '',
            show: true
          })
        } else if (this.type === 'invoice-products') {
          this.data.push({
            code: '',
            name: '',
            properties: [],
            currentProperty: {
              label: '-',
              value: 0
            },
            stores: [],
            store: {
              label: '-',
              value: 0
            },
            number: 0,
            unitAmount: '',
            amount: '',
            show: true
          })
        } else if (this.type === 'purchases-invoice-products') {
          this.data.push({
            code: '',
            name: '',
            properties: [],
            currentProperty: {
              label: '-',
              value: 0
            },
            number: 0,
            unitAmount: '',
            amount: '',
            show: true
          })
        }
      }

      /*if (this.dataChecker.length < this.data.length) {
        this.dataChecker = []
        this.data.forEach((data) => {
          this.dataChecker.push(data)
        })

        if (this.type === 'users') {
          this.data.push({
            id: '',
            name: '',
            family: '',
            phone_number: '',
            status: '',
            price: '',
            show: true
          })
        } else if (this.type === 'stores') {
          this.data.push({
            code: '',
            name: '',
            properties: [],
            currentProperty: {
              label: '-',
              value: 0
            },
            number: 0,
            unitAmount: '',
            amount: '',
            show: true
          })
        }
      }*/
    },
    removeRow (item) {
      if (this.data.length > 1) {
        const refresh_data = []

        if (this.type === 'users') {
          this.data.forEach((data) => {
            if (data.id !== item.id) refresh_data.push(data)
          })

        }


        this.dataChecker = []
        for (let i = 0; i < this.data.length - 2; i++) {
          this.dataChecker.push(this.data[i])
        }
        this.$emit('input', refresh_data)
      }
    },
    getDataCount () {
      if (this.enableCounter && Object.keys(this.enableCounter).length > 0) {
        this.enableCounter.active_index.forEach((index) => {
          this.counter[index.index] = 0
          this.data.forEach((item) => {
            if (this.counter[index.index]) {
              if (parseInt(item[index.name].toString().replaceAll(',', ''))) {
                if (this.type === 'users') {
                  this.counter[index.index] += parseInt(item[index.name].toString().replaceAll(',', '')) * (item.status === 'بستانکار' ? -1 : 1)
                } else {
                  this.counter[index.index] += parseInt(item[index.name].toString().replaceAll(',', ''))
                }
              }
            } else if (parseInt(item[index.name].toString().replaceAll(',', ''))) {
              if (this.type === 'users') {
                this.counter[index.index] = parseInt(item[index.name].toString().replaceAll(',', '')) * (item.status === 'بستانکار' ? -1 : 1)
              } else {
                this.counter[index.index] = parseInt(item[index.name].toString().replaceAll(',', ''))
              }
            }
          })
        })
      }
    }
  },
  watch: {
    data: {
      handler (val) {
        this.suggestsList = []
        this.suggests.forEach((suggest) => {
          this.suggestsList.push(suggest)
        })

        if (this.type === 'users' || this.type === 'stores' || this.type === 'invoice-products' || this.type === 'purchases-invoice-products') {
          this.getDataCount()
        }

        for (let i = 0; i < val.length; i++) {

          if (this.type === 'users') {
            val[i].price = this.addComma(val[i].price)

          } else if (this.type === 'stores' || this.type === 'invoice-products' || this.type === 'purchases-invoice-products') {
            val[i].amount = this.addComma(val[i].amount)
            val[i].unitAmount = this.addComma(val[i].unitAmount)
          }

          if (this.type === 'users') {
            this.removeItem(val[i].id)
          }
        }
      },
      deep: true
    },
    suggests: {
      handler () {

        this.suggestsList = []
        this.suggests.forEach((suggest) => {
          this.suggestsList.push(suggest)
        })
      },
      deep: true
    }
  },
  filters: {
    addComma (price) {
      let sign = ''

      if (price < 0) sign = '-'

      price = price.toString()

      if (price[0] === '0') price = price.substr(1, price.length - 1)

      return price.toString().replace(/\D/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + sign
    }
  }
}
</script>

<style lang="scss">
.dynamic-input-table {

  table {
    border-collapse: collapse;
  }

  .table-header {
    line-height: 35px;
    background: #131314 !important;
  }

  .table-footer {
    line-height: 35px;
    background: #131314 !important;

    .counter-label {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 10px;
      display: block;
      height: fit-content;
      line-height: 35px;
    }

    .counter-value {
      display: block;
      text-align: right;
      padding-right: 45px;
      line-height: 29px;
    }
  }

  th {
    text-align: left;
  }

  td {
    position: relative;
    padding: 0 5px;
  }

  tr {

    div.select-suggest-list {
      right: 0 !important;
      left: 0 !important;
    }

    &:nth-child(2n) {
      background: #1b1b1c;

      input,
      div.useral-custom-element-select div.selected,
      div.useral-custom-element-select div.items,
      div.select-suggest-list {
        background: #1b1b1c !important;
      }

      .useral-custom-element-select .items div:hover {
        background: #101011 !important;
      }

      .select-suggest-list .select-suggest-item {
         border-top: 1px solid #101011 !important;
       }
    }

    &:nth-child(2n+1) {
      background: #101011;

      input,
      div.useral-custom-element-select div.selected,
      div.useral-custom-element-select div.items,
      div.select-suggest-list {
        background: #101011 !important;
      }

      .useral-custom-element-select .items div:hover {
        background: #1b1b1c !important;
      }

      .select-suggest-list .select-suggest-item {
        border-top: 1px solid #1b1b1c !important;
      }
    }
  }
}
</style>
