<template>
  <div class="sale-closed-calendar">

    <div class="date-items">
      <button class="prev-date-btn" :disabled="!checkUserPermissions('closed_invoice.dynamicDate') && futureWeeks === 0" @click="changeTableDay(-1)">
        {{ $t('closed.labels.lastWeek') }}
      </button>
      <div class="date-items">
        <div class="date-content">
          <template v-for="i in 7">
            <button class="date-btn"
                    :class="selectedTableDay > -1 && i === selectedTableDay || selectedTableDay === -1 && i - 1 === weekIndex ? 'active' : ''"
                    :disabled="!checkUserPermissions('closed_invoice.dynamicDate') && futureWeeks === 0 && i - 1 < weekIndex"
                    @click="setTableDate(i)">
              {{ getTableDate($localeValidator('moment.dateWithWeek'), (futureWeeks * 7) + i - weekIndex - 1) }}
              <span v-if="tableDayReserveCount[i - 1] > 0" class="day-reserve-count">
                {{ tableDayReserveCount[i - 1] }}
              </span>
            </button>
          </template>
        </div>
      </div>
      <button class="next-date-btn" @click="changeTableDay(+1)">
        {{ $t('closed.labels.futureWeek') }}
      </button>
    </div>

    <div class="time-table">
      <div class="time-table-body">
        <div v-if="timesList.length > 0"
             class="court-row time-label">
          <div class="court-name">{{ $t('closed.labels.timeCourt') }}</div>
          <select-time v-for="(item, time_index) in timesList[0].times"
                       :time="item.time"
                       :court="item.court"
                       :price="0"
                       :term-id="item.term_id"
                       :is-label="true"
                       :disabled="true"
                       :date="getTableDate()"
                       :selected-times="selectedTimes"/>
        </div>
        <div v-if="courtItem.times && courtItem.times.length > 0"
             class="court-row"
             v-for="(courtItem, court_index) in timesList"
             :key="`court_${court_index}`">
          <div class="court-name">
            {{ courtItem ? courtItem.court_name : '' }}
            <input class="select-court-row-input" type="checkbox" @click="handleCheckCourtRow($event, courtItem)" />
          </div>
          <select-time v-for="(item, time_index) in courtItem.times"
                       :time="item.time"
                       :court="item.court"
                       :price="item.price"
                       :term-id="item.term_id"
                       :reload="reloadTimeStatus === court_index"
                       :current-time="getCurrentHour(item)"
                       :customer="item.customer"
                       :type="item.type"
                       :disabled="item.status <= 0 || parseInt(item.price) === 0 || getCurrentHour(item) || !item.active"
                       :date="getTableDate()"
                       selected-color="danger"
                       :selected-times="selectedTimes"
                       @time:selected="handleTimeSelected($event)"
                       @time:unselected="handleTimeUnselected($event)"/>
        </div>
      </div>
    </div>

    <!-- show selected times prompt -->
    <vs-prompt
      class="very-big-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="confirmTimesPromptStatus"
      @close="confirmTimesPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('confirmClosedPriceBTN')">
              <custom-icon icon="CHECK" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('closed.insert.confirms.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="confirmTimesPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <confirm-closed-times :action-type="newInvoice.actionType" :selected-times="selectedTimes"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <vs-button id="setTimeSelected" class="useral-action-button" @click="handleShowReserveConfirm"/>
    <vs-button id="emitTimeSelected" class="useral-action-button" @click="$emit('selected', selectedTimes)"/>
  </div>
</template>

<script>
  import moment from 'moment-jalaali'
  import {getReservableTimes} from "../../../../../http/requests/booking/sales"
  import SelectTime from "../../../../../components/reserveTime/selectTime.vue";
  import CustomIcon from "../../../../../components/customIcon/customIcon.vue";
  import {showLoading, hideLoading, getTimeFromServer, checkUserPermissions} from "../../../../../assets/js/functions";
  import ConfirmClosedTimes from "./confirmClosedTimes.vue";

  export default {
    name: "salesClosedCalendar",
    components: {ConfirmClosedTimes, CustomIcon, SelectTime},
    metaInfo() {
      return {
        title: this.$t('closed.insert.title')
      }
    },
    data() {
      return {
        confirmTimesPromptStatus: false,
        currentTime: moment(getTimeFromServer()).format(this.$localeValidator('moment.date')),
        futureWeeks: 0,
        getCurrentHourTimer: 0,
        weekIndex: 0,
        selectedTableDay: -1,
        reloadTimeStatus: false,
        timesList: [],
        tableDayReserveCount: [],
        selectedTimes: {},
        newInvoice: {
          id: {
            value: '',
            isValid: true
          },
          actionType: {
            value: 1,
            label: ''
          },
          status: {},
          user: {
            id: 0,
            name: {
              value: '',
              isValid: true
            }
          },
          date: {
            value: moment(getTimeFromServer()).format(this.$localeValidator('moment.date')),
            isValid: true
          }
        },
        actions: [
          {
            toolbar: [
              {
                id: 'setTimeSelected',
                icon: 'NOT_ALLOWED',
                iconPack: 'useral',
                color: 'danger',
                badge: {
                  value: 0,
                },
                permission: 'closed_invoice.create'
              }
            ],
            leftToolbar: [
              {
                id: {name: 'closedInvoices'},
                type: 'link',
                icon: 'CHEVRON_LEFT',
                iconPack: 'useral'
              }
            ]
          }
        ]
      }
    },
    created() {
      if (this.$route.name === 'insertClosedInvoices') {
        this.$nextTick(() => {
          this.$store.dispatch('updateNavbarActions', this.actions[0])
        })
      }
      this.currentTime = getTimeFromServer()

      this.weekIndex = (moment(this.currentTime).day() + 1) % 7
      this.selectedTableDay = this.weekIndex + 1
      this.getReservableTimes()
      this.getReserveTableCount()
    },
    mounted () {
      setTimeout(() => {
        showLoading()
      }, 480)

      this.getCurrentHourTimer = setTimeout(() => {
        this.getCurrentHourTimer = 0
      }, 60000 - parseInt(moment(this.currentTime).format(this.$localeValidator('moment.second'))) * 1000)
    },
    methods: {
      checkUserPermissions,
      getCurrentHour (item) {
        return moment(this.currentTime).format(this.$localeValidator('moment.dateHour')) === `${item.date} ${item.time.start_time.split(':')[0]}`
      },
      getTableDate(formatValue = this.$localeValidator('moment.date'), days = (this.futureWeeks * 7) + this.selectedTableDay - this.weekIndex - 1) {
        return moment(this.currentTime).locale(this.$locale.getLanguage()).add(days, 'days').format(formatValue)
      },
      setTableDate(index = this.selectedTableDay) {
        this.selectedTableDay = index
        const days = (this.futureWeeks * 7) + index - this.weekIndex - 1
        this.timesList = []
        this.getReservableTimes(days)
      },
      changeTableDay(status) {
        if (status > 0) {
          this.futureWeeks += 1
          this.selectedTableDay = 1
        } else {
          this.futureWeeks -= 1
          this.selectedTableDay = 7
        }
        this.timesList = []
        this.setTableDate()
        this.getReserveTableCount()
      },

      getReservableTimes(days) {
        showLoading()
        const tableType = checkUserPermissions('closed_invoice.dynamicDate') ? 4 : 1
        getReservableTimes(1, {type: `${tableType}`, date: `${this.getTableDate(this.$localeValidator('moment.date'), days)}`}).then((response) => {
          const data = response.data.data
          let totalTimes = {}
          data.forEach(time => {
            if (!totalTimes[`court_${time.court.id}`]) {
              let court = time.court
              court.times = []
              totalTimes[`court_${time.court.id}`] = court
            }

            switch (time.status) {
              case 0:
                time.type = 'closed'
                break

              case 1:
                time.type = time.active ? 'selectable' : 'disable'
                break

              case 2:
                time.type = time.active ? 'selectable' : 'reserved'
                break
            }
            totalTimes[`court_${time.court.id}`].times.push(JSON.parse(JSON.stringify(time)))
          })
          this.timesList = Object.values(totalTimes)
          hideLoading()
        })
          .catch(error => {
            hideLoading()
          })
      },
      handleTimeSelected(selectedTime) {
        delete selectedTime.court.times
        this.selectedTimes[`${selectedTime.date}_${selectedTime.time.id}_${selectedTime.court.id}`] = selectedTime
        if (this.$route.name === 'insertClosedInvoices') {
          setTimeout(() => {
            let actions = JSON.parse(JSON.stringify(this.actions))
            actions[0].toolbar[0].badge.value = Object.values(this.selectedTimes).length
            this.$store.dispatch('updateNavbarActions', actions[0])
          }, 100)
        }

        this.getReserveTableCount()

      },
      handleTimeUnselected(unselectedTime) {
        delete this.selectedTimes[`${unselectedTime.date}_${unselectedTime.time.id}_${unselectedTime.court.id}`]
        if (this.$route.name === 'insertClosedInvoices') {
          setTimeout(() => {
            let actions = JSON.parse(JSON.stringify(this.actions))
            actions[0].toolbar[0].badge.value = Object.values(this.selectedTimes).length
            this.$store.dispatch('updateNavbarActions', actions[0])
          }, 100)
        }
        this.getReserveTableCount()
      },
      handleShowReserveConfirm () {
        if (Object.values(this.selectedTimes).length > 0) {
          this.confirmTimesPromptStatus = true
        } else {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('closed.notifications.noTime'),
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400
          })
        }
      },
      handleCheckCourtRow (event, selected) {
        const checked = event.target.checked
        if (checked) {
          for (let i = 0; i < selected.times.length; i++) {
            const item = selected.times[i]
            const disabled = (item.status <= 0 || parseInt(item.price) === 0 || this.getCurrentHour(item) || !item.active)
            if (!disabled) {
              const selectedTime = {
                time: item.time,
                court: item.court,
                date: item.date,
                price: item.price,
                discount: '0',
                termId: item.term_id
              }
              this.handleTimeSelected(selectedTime)
            }
          }
        } else {
          for (let i = 0; i < selected.times.length; i++) {
            const item = selected.times[i]
            const disabled = (item.status <= 0 || parseInt(item.price) === 0 || this.getCurrentHour(item) || !item.active)
            if (!disabled) {
              const selectedTime = {
                time: item.time,
                court: item.court,
                date: item.date,
                price: item.price,
                discount: '0',
                termId: item.term_id
              }
              this.handleTimeUnselected(selectedTime)
            }
          }
        }

        this.reloadTimeStatus = this.timesList.indexOf(selected)
        setTimeout(() => {
          this.reloadTimeStatus = false
        }, 500)
      },
      getReserveTableCount () {
        const selectedTimes = Object.keys(this.selectedTimes)
        let timeCounts = []
        for (let i = 1; i <= 7; i++) {
          let count = 0
          const date = this.getTableDate(this.$localeValidator('moment.date'), (this.futureWeeks * 7) + i - this.weekIndex - 1)
          selectedTimes.forEach((time) => {
            if (time.split('_')[0] === date) {
              count++
            }
          })
          timeCounts.push(count)
        }
        this.tableDayReserveCount = timeCounts
      },
      handleClick(id) {
        document.getElementById(id).click()
      },
    },

    watch: {
      getCurrentHourTimer: {
        handler (val) {
          if (val === 0) {
            this.$forceUpdate()
            this.getCurrentHourTimer = setTimeout(() => {
              this.getCurrentHourTimer = 0
            }, 60000)
          }
        }
      }
    }
  }
</script>

<style lang="scss">
  @import "../../../../../assets/scss/vuexy/variables";
  .sale-closed-calendar {
    $danger: #ea5455;

    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    height: 100%;

    .date-items {
      display: flex;
      align-items: center;
      background-color: #f0f0f0;
      border: 1px solid #f0f0f0;

      .prev-date-btn,
      .next-date-btn {
        padding: 5px 20px;
        margin: 10px;
        background-color: #f8f8f8;
        border: 1px solid #cecece;
        border-radius: 0.5rem;

        &:hover:not([disabled]) {
          cursor: pointer;
          background-color: #f0f0f0;
        }

        &:disabled {
          cursor: not-allowed;
        }
      }

      .date-items {
        display: flex;
        justify-content: center;
        flex-grow: 1;
        overflow: auto;
        .date-content {
          display: flex;
          text-align: center;
          overflow-x: auto;
          padding: 5px;
          padding-top: 10px;

          &::-webkit-scrollbar {
            display: block;
            background: rgba(255, 255, 255, 0.50);
            height: 10px;
            width: 10px;
            border-radius: 0.5rem;
          }

          &::-webkit-scrollbar-thumb {
            display: block;
            background: rgba(206, 206, 206, 1);
            height: 6px;
            border-radius: .5rem;
          }

          .date-btn {
            position: relative;
            padding: 5px 20px;
            margin: 0 5px;
            background-color: #f8f8f8;
            border: 1px solid #cecece;
            border-radius: 0.5rem;
            white-space: nowrap;

            &:hover:not([disabled]) {
              cursor: pointer;
              background-color: #f0f0f0;
            }

            &.active {
              background-color: $sidebar-semi-bg;
              color: #ffffff;

              &:hover {
                background-color: #1b2027;
              }
            }

            &:disabled {
              cursor: not-allowed;
            }

            .day-reserve-count {
              position: absolute;
              top: -10px;
              right: calc(50% - 8px);
              height: 16px;
              width: 16px;
              background-color: $danger;
              color: #ffffff;
              border-radius: 50%;
            }
          }
        }
      }
    }

    .time-table {
      position: relative;
      flex: 1;
      width: 100%;
      overflow-x: auto;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: start;
      flex-wrap: wrap;
      padding: 10px;
      /*padding-left: 170px;*/



      &::-webkit-scrollbar {
        display: block;
        background: rgba(255, 255, 255, 0.50);
        height: 10px;
        width: 10px;
        border-radius: 0.5rem;
      }

      &::-webkit-scrollbar-thumb {
        display: block;
        background: rgba(206, 206, 206, 1);
        height: 6px;
        border-radius: .5rem;
      }

      .time-table-body {
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: row;
      }

      .court-row {
        display: flex;
        flex-direction: column;
        width: 150px;

        .court-name {
          width: 100%;
          line-height: 60px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: flex;
          align-items: center;
          justify-content: center;
          background: $sidebar-semi-bg;
          /*border-radius: 0.5rem 0.5rem 0 0;*/
          position: sticky;
          top: -11px;
          color: #ffffff;
          font-weight: 500;
          border-left: 1px solid #cdcdcd;
          border-bottom: 1px solid #cdcdcd;

          .select-court-row-input {
            position: absolute;
            top: 5px;
            right: 5px;
            height: 15px;
            width: 15px;
          }
        }

        &:first-child {
          .court-name {
            border-radius: 0.5rem 0 0 0;
            border-left: none;
          }
        }

        &:last-child {
          .court-name {
            border-radius: 0 0.5rem 0 0;
          }

          .select-hour-box:last-child {
            button {
              border-radius: 0 0 0.5rem 0;
            }
          }
        }

        .select-hour-box {
          .active {
            background-color: $danger;

            span {
              color: #fff !important;
            }
          }
        }
      }

      .time-label {
        position: sticky;
        left: -10px;
        /*background-color: #f8f8f8;*/
        z-index: 100;
        width: 100px;

        .select-hour-box {
          .is-label {
            background-color: $sidebar-semi-bg;
            color: #ffffff;
          }
        }

        .select-hour-box:last-child {
          .is-label {
            border-radius: 0 0 0 0.5rem;
          }
        }
      }
    }
  }
</style>
